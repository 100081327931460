import { useState } from "react";
import { BASE_URL } from "../components/BaseUrl";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";

const useLogin = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    setLoading(true);
    axios
      .post(`${BASE_URL}/admin-auth/signin`, {
        email: email.trim(),
        password: password.trim(),
      })
      .then((res) => {
        console.log(res);
        localStorage.setItem("admin", JSON.stringify(res.data.user));
        localStorage.setItem("access", res.data.auth.access);
        dispatch({ type: "LOGIN", payload: res.data.user });
        setSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
        setSuccess(false);
      });
  };
  return { login, loading, error, success };
};

export default useLogin;
