import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { useNavigate } from "react-router-dom";
import { capitalize } from "@mui/material";

const YieldLeaderboard = () => {
  const [yields, setYields] = useState([]);
  const navigate = useNavigate();

  async function getYields() {
    try {
      const { data } = await axios.get(`${BASE_URL}/txns/fixed-yields`);
      setYields(data.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteFixedYield(id) {
    axios
      .delete(`${BASE_URL}/txns/fixed-yield/${id}`)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        alert(err);
      });
  }
  useEffect(() => {
    getYields();
  }, []);
  return (
    <div>
      {yields.length > 0 ? (
        yields.map((fixedYield) => {
          return (
            <div className="sm:grid grid-cols-5 gap-8 bg-[#181b20] my-3 rounded-2xl sm:rounded-full py-4 px-8 hover:opacity-75 duration-300">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-lg sm:text-xs text-gray-400 font-medium">
                    Name
                  </p>
                  <div className="flex items-center space-x-2 mt-1">
                    <div>
                      <img
                        className="rounded-full w-6 h-6"
                        src={fixedYield.image}
                        alt="strategy"
                      />
                    </div>
                    <div>
                      <h1 className="text-gray-200 uppercase text-lg font-medium">
                        {fixedYield.symbol}
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-gray-400">
                        {capitalize(fixedYield.name)}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-[2px] bg-gray-700 rounded-full sm:hidden w-full my-2"></div>
              <div className="max-[640px]:flex items-center justify-between max-[640px]:py-3">
                <p className="text-lg sm:text-xs text-gray-400 font-medium">
                  APY
                </p>
                <h1 className="text-xl text-gray-300 mt-1">
                  {fixedYield.apy}%
                </h1>
              </div>
              <div className="max-[640px]:flex items-center justify-between">
                <p className="text-lg sm:text-xs text-gray-400 font-medium">
                  Price
                </p>
                <h1 className="text-xl text-gray-300 mt-1 tracking-wider">
                  ${fixedYield.price}
                </h1>
              </div>
              <div className="max-[640px]:hidden">
                <p className="text-xs text-gray-400 font-medium">24h %</p>
                <div className="flex items-center space-x-2 mt-1">
                  <h1
                    className={`${
                      fixedYield.pricePercentageChange.includes("-")
                        ? "text-red-500"
                        : "text-green-500"
                    }`}
                  >
                    {fixedYield.pricePercentageChange}%
                  </h1>
                  <p className="text-gray-500 font-medium">
                    (${fixedYield.priceChange})
                  </p>
                </div>
              </div>
              <div className="md:flex space-x-3 items-center">
                <button
                  onClick={() => {
                    navigate(`/fixed-yield/${fixedYield._id}`);
                  }}
                  className="py-1 px-4 max-[640px]:my-1 max-[640px]:w-full text-gray-700 bg-[#b2ffce] rounded-xl sm:rounded-2xl font-medium"
                >
                  Update
                </button>
                <button
                  onClick={() => deleteFixedYield(fixedYield._id)}
                  className="py-1 px-4 max-[640px]:my-1 max-[640px]:w-full text-white bg-red-500 rounded-xl sm:rounded-2xl font-medium"
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <div>
          <h1 className="text-white text-center text-lg font-medium">
            There is no data to display. Please add more yields.
          </h1>
        </div>
      )}
    </div>
  );
};

export default YieldLeaderboard;
