import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const InvestmentTable = () => {
  const [investments, setInvestments] = useState([]);

  async function getInvestments() {
    try {
      const { data } = await axios.get(`${BASE_URL}/txns/investments`);
      setInvestments(
        data.data.filter((data) => data.status === "active" && data)
      );
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getInvestments();
  }, []);

  const columns = [
    {
      name: "User",
      selector: (row) => row.user,
    },
    {
      name: "Pool Name",
      selector: (row) => row.title,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Earnings",
      selector: (row) => row.earnings,
    },
    {
      name: "Entry",
      selector: (row) => row.entry,
    },
    {
      name: "Exit",
      selector: (row) => row.exit,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
  ];
  const data = investments?.map((investment, index) => {
    return {
      id: index,
      title: investment.pool,
      date: investment.createdAt.slice(0, 10),
      status: investment.status,
      amount: investment.amount,
      entry: investment.performance,
      exit: investment.exit,
      earnings: investment.earnings,
      user: investment.user?.name,
    };
  });
  return (
    <div>
      <DataTable pagination columns={columns} data={data} />
    </div>
  );
};

export default InvestmentTable;
