export function returnActiveInvestments(investmentsArr) {
  const _active = investmentsArr.filter(
    (investment) => investment.status === "active" && investment
  );
  return _active;
}

export function returnActivePools(poolsArr) {
  const _active = poolsArr.filter((pool) => pool.active === true && pool);
  return _active;
}

export function returnPendingDeposits(depositsArr) {
  const _pending = depositsArr.filter(
    (deposit) => deposit.status === "pending" && deposit
  );
  return _pending;
}

export function returnPendingWithdrawals(withdrawalArr) {
  const _pending = withdrawalArr.filter(
    (withdrawal) => withdrawal.status === "pending" && withdrawal
  );
  return _pending;
}

export function returnPendingKycs(kycs) {
  const _pending = kycs.filter((kyc) => kyc.status === "pending" && kyc);
  return _pending;
}
