import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import SidebarComponent from "../../components/dashboard/Sidebar";
import Loader from "../../components/Loader";
import axios from "axios";
import { BASE_URL } from "../../components/BaseUrl";
import CircularProgress from "@mui/material/CircularProgress";

const NewFaq = () => {
  const { admin } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [defaultData, setDefaultData] = useState({
    title: "",
    body: "",
  });

  async function newFaq(e) {
    setLoading(true);
    e.preventDefault();
    const data = {
      title: defaultData.title,
      body: defaultData.body,
    };
    axios
      .post(`${BASE_URL}/faq/new`, data)
      .then((res) => {
        alert("FAQ created successfully");
        setLoading(false);
        console.log(res);
      })
      .catch((error) => {
        console.log(error.response.data);
        alert("An error occured, please try again later");
        setLoading(false);
      });
  }

  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          <div>
            <div>
              <h1 className="text-gray-400 text-xl font-medium">New FAQ</h1>
            </div>
            <form onSubmit={newFaq}>
              <div className="md:grid grid-cols-3 gap-10 mt-10">
                <div>
                  <label htmlFor="title" className="text-gray-300 text-sm">
                    Title
                  </label>
                  <input
                    name="title"
                    onChange={(e) =>
                      setDefaultData({ ...defaultData, title: e.target.value })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="body" className="text-gray-300 text-sm">
                    Body
                  </label>
                  <textarea
                    rows={10}
                    columns={20}
                    name="body"
                    onChange={(e) =>
                      setDefaultData({
                        ...defaultData,
                        body: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  ></textarea>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="md:w-[150px] mt-4 w-full bg-[#b2ffce] px-5 py-2 rounded-md font-medium"
                >
                  {loading ? (
                    <CircularProgress color="inherit" size="25px" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFaq;
