import React, { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import SidebarComponent from "../../components/dashboard/Sidebar";
import Loader from "../../components/Loader";
import Users from "../../components/dashboard/users";

const UsersPage = () => {
  const { admin } = useContext(AuthContext);
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f]">
        <div className="p-4 border border-gray-600 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <Users page={true} />
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
