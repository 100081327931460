import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import SidebarComponent from "../../components/dashboard/Sidebar";
import Loader from "../../components/Loader";
import axios from "axios";
import { BASE_URL } from "../../components/BaseUrl";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";

const Pool = () => {
  const { id } = useParams();
  const { admin } = useContext(AuthContext);
  const [pool, setPool] = useState({});
  const [defaultPool, setDefaultPool] = useState({
    name: pool?.name || "",
    manager: pool?.manager || "",
    valueManaged: pool?.valueManaged || "",
    lifetimeReturn: pool?.lifetimeReturn || "",
    performance: pool?.performance || "",
    riskFactor: pool?.riskFactor || "",
    accountType: pool?.accountType || "",
    investors: pool?.investors || "",
    oneMonthReturn: pool?.oneMonthReturn || "",
    threeMonthsReturn: pool?.threeMonthsReturn || "",
    sixMonthsReturn: pool?.sixMonthsReturn || "",
    yearlyReturn: pool?.yearlyReturn || "",
    about: pool?.about || "",
    active: pool?.active || true,
    image: pool?.image || "",
    managerHolding: pool?.managerHolding || "",
    category: pool?.category || "",
    strategyWallet: pool?.strategyWallet || "",
    weeklyReturn: pool?.weeklyReturn || "",
    dailyReturn: pool?.dailyReturn || "",
    managementFeesEarned: pool?.managementFeesEarned || "",
    totalLockedValue: pool?.totalLockedValue || "",
    strategyProsAndCons: pool?.strategyProsAndCons || "",
    details: pool?.details || "",
    managementFee: pool?.managementFee | "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/txns/pool/${id}`)
      .then((res) => {
        console.log(res);
        setPool(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    console.log(pool);
    setDefaultPool({
      name: pool.name,
      manager: pool.manager,
      valueManaged: pool.valueManaged,
      lifetimeReturn: pool.lifetimeReturn,
      performance: pool.performance,
      riskFactor: pool.riskFactor,
      accountType: pool.accountType,
      investors: pool.investors,
      oneMonthReturn: pool.oneMonthReturn,
      threeMonthsReturn: pool.threeMonthsReturn,
      sixMonthsReturn: pool.sixMonthsReturn,
      yearlyReturn: pool.yearlyReturn,
      about: pool.about,
      active: pool.active,
      image: pool.image,
      managerHolding: pool.managerHolding,
      category: pool.category,
      strategyWallet: pool.strategyWallet,
      weeklyReturn: pool.weeklyReturn,
      dailyReturn: pool.dailyReturn,
      managementFeesEarned: pool.managementFeesEarned,
      totalLockedValue: pool.totalLockedValue,
      strategyProsAndCons: pool.strategyProsAndCons,
      details: pool.details,
      managementFee: pool.managementFee,
    });
  }, [pool]);

  const categories = [
    "Defi",
    "Market Yield",
    "Hedging",
    "Arbitrage",
    "Rebalancing",
  ];

  async function updateDetails(data) {
    try {
      setLoading(true);
      axios
        .post(`${BASE_URL}/admin/pool/update/${pool._id}`, {
          name: data.name,
          manager: data.manager,
          valueManaged: data.valueManaged,
          lifetimeReturn: data.lifetimeReturn,
          performance: data.performance,
          riskFactor: data.riskFactor,
          accountType: pool.accountType,
          investors: data.investors,
          oneMonthReturn: data.oneMonthReturn,
          threeMonthsReturn: data.threeMonthsReturn,
          sixMonthsReturn: data.sixMonthsReturn,
          yearlyReturn: data.yearlyReturn,
          about: data.about,
          active: data.active,
          image: data.image,
          managerHolding: data.managerHolding,
          category: data.category,
          strategyWallet: data.strategyWallet,
          weeklyReturn: data.weeklyReturn,
          dailyReturn: data.dailyReturn,
          managementFeesEarned: data.managementFeesEarned,
          totalLockedValue: data.totalLockedValue,
          strategyProsAndCons: data.strategyProsAndCons,
          details: data.details,
          managementFee: data.managementFee,
        })
        .then((res) => {
          alert("Pool update successfully");
          setLoading(false);
          console.log(res);
        })
        .catch((error) => {
          console.log("Error: " + error);
          alert("An error occured, please try again later");
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      alert("An error occured, please try again later");
      setLoading(false);
    }
  }

  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          {pool && (
            <div>
              <div>
                <h1 className="text-gray-400 text-xl font-medium">Settings</h1>
              </div>
              <form className="md:grid grid-cols-3 gap-10 mt-10">
                <div>
                  <label htmlFor="name" className="text-gray-300 text-sm">
                    Name
                  </label>
                  <input
                    name="name"
                    defaultValue={pool.name}
                    onChange={(e) =>
                      setDefaultPool({ ...defaultPool, name: e.target.value })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="manager" className="text-gray-300 text-sm">
                    Manager
                  </label>
                  <input
                    name="manager"
                    defaultValue={pool.manager}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        manager: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="image" className="text-gray-300 text-sm">
                    Manager Image
                  </label>
                  <input
                    name="image"
                    defaultValue={pool.image}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        image: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="valueManaged"
                    className="text-gray-300 text-sm"
                  >
                    Value Managed
                  </label>
                  <input
                    name="valueManaged"
                    defaultValue={pool.valueManaged}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        valueManaged: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="lifetimeReturn"
                    className="text-gray-300 text-sm"
                  >
                    Lifetime Return
                  </label>
                  <input
                    name="lifetimeReturn"
                    defaultValue={pool.lifetimeReturn}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        lifetimeReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>

                <div>
                  <label
                    htmlFor="performance"
                    className="text-gray-300 text-sm"
                  >
                    Performance
                  </label>
                  <input
                    name="performance"
                    defaultValue={pool.performance}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        performance: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="managementFee"
                    className="text-gray-300 text-sm"
                  >
                    Management Fee
                  </label>
                  <input
                    name="managementFee"
                    defaultValue={pool.managementFee}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        managementFee: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="riskFactor" className="text-gray-300 text-sm">
                    Risk Factor
                  </label>
                  <input
                    name="riskFactor"
                    defaultValue={pool.riskFactor}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        riskFactor: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="investors" className="text-gray-300 text-sm">
                    Investors
                  </label>
                  <input
                    name="investors"
                    defaultValue={pool.investors}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        investors: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="oneMonthReturn"
                    className="text-gray-300 text-sm"
                  >
                    One Month Return
                  </label>
                  <input
                    name="oneMonthReturn"
                    defaultValue={pool.oneMonthReturn}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        oneMonthReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="threeMonthsReturn"
                    className="text-gray-300 text-sm"
                  >
                    Three Months Return
                  </label>
                  <input
                    name="threeMonthsReturn"
                    defaultValue={pool.threeMonthsReturn}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        threeMonthsReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="sixMonthsReturn"
                    className="text-gray-300 text-sm"
                  >
                    Six Months Return
                  </label>
                  <input
                    name="sixMonthsReturn"
                    defaultValue={pool.sixMonthsReturn}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        sixMonthsReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="yearlyReturn"
                    className="text-gray-300 text-sm"
                  >
                    Yearly Return
                  </label>
                  <input
                    name="yearlyReturn"
                    defaultValue={pool.yearlyReturn}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        yearlyReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="managerHolding"
                    className="text-gray-300 text-sm"
                  >
                    Manager Holding (%)
                  </label>
                  <input
                    name="managerHolding"
                    defaultValue={pool.managerHolding}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        managerHolding: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="category" className="text-gray-300 text-sm">
                    Category
                  </label>
                  <select
                    defaultValue={pool.category}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        category: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  >
                    {categories.map((category) => {
                      console.log(category);
                      return <option>{category.toString()}</option>;
                    })}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="strategyWallet"
                    className="text-gray-300 text-sm"
                  >
                    Strategy Wallet
                  </label>
                  <input
                    name="strategyWallet"
                    defaultValue={pool.strategyWallet}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        strategyWallet: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="weeklyReturn"
                    className="text-gray-300 text-sm"
                  >
                    Weekly Return
                  </label>
                  <input
                    name="weeklyReturn"
                    defaultValue={pool.weeklyReturn}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        weeklyReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="dailyReturn"
                    className="text-gray-300 text-sm"
                  >
                    Daily Return
                  </label>
                  <input
                    name="dailyReturn"
                    defaultValue={pool.dailyReturn}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        dailyReturn: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="managementFeesEarned"
                    className="text-gray-300 text-sm"
                  >
                    Management Fees Earned
                  </label>
                  <input
                    name="managementFeesEarned"
                    defaultValue={pool.managementFeesEarned}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        managementFeesEarned: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="totalLockedValue"
                    className="text-gray-300 text-sm"
                  >
                    Total Locked Value
                  </label>
                  <input
                    name="totalLockedValue"
                    defaultValue={pool.totalLockedValue}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        totalLockedValue: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="strategyProsAndCons"
                    className="text-gray-300 text-sm"
                  >
                    Strategy Pros and Cons Link
                  </label>
                  <input
                    name="strategyProsAndCons"
                    defaultValue={pool.strategyProsAndCons}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        strategyProsAndCons: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="details" className="text-gray-300 text-sm">
                    Strategy Details Link
                  </label>
                  <input
                    name="details"
                    defaultValue={pool.details}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        details: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="active" className="text-gray-300 text-sm">
                    Active
                  </label>
                  <input
                    name="active"
                    defaultValue={pool.active}
                    onChange={(e) =>
                      setDefaultPool({
                        ...defaultPool,
                        active: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
              </form>
              <div className="mt-3">
                <label htmlFor="about" className="text-gray-300 text-sm">
                  About
                </label>
                <MdEditor
                  defaultValue={pool.about}
                  renderHTML={(text) => MarkdownIt().render(text)}
                  onChange={({ text }) =>
                    setDefaultPool({
                      ...defaultPool,
                      about: text,
                    })
                  }
                />
              </div>
              <div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    updateDetails(defaultPool);
                  }}
                  type="submit"
                  className="md:w-[150px] mt-4 w-full bg-[#b2ffce] px-5 py-2 rounded-md font-medium"
                >
                  {loading ? (
                    <CircularProgress color="inherit" size="25px" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
              <div className="bg-[#181b20] px-5 py-9 my-14 rounded-2xl"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pool;
