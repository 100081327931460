import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { returnPendingWithdrawals } from "../../utils/filter";

createTheme("default", {
  text: {
    primary: "#fff",
    secondary: "#fff",
  },
  background: {
    default: "#181b20",
  },
});

const Withdrawals = ({ pending = false }) => {
  const [withdrawals, setWithdrawals] = useState([]);
  const [pendingWithdrawals, setPendingWithdrawals] = useState([]);

  async function getWithdrawals() {
    try {
      const { data } = await axios.get(`${BASE_URL}/txns/withdrawals`);
      setWithdrawals(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getWithdrawals();
  }, []);

  useEffect(() => {
    // FILTER WITHDRAWALS
    if (withdrawals) {
      const pending = returnPendingWithdrawals(withdrawals);
      setPendingWithdrawals(pending);
    }
  }, [withdrawals]);

  async function approveWithdrawal(id) {
    await axios
      .post(`${BASE_URL}/txns/approve-withdrawal`, {
        id,
      })
      .then(() => {
        alert("Withdrawal approved successfully!");
      })
      .catch((err) => {
        alert("An error occured. Please try again later!");
      });
  }

  async function declineWithdrawal(id) {
    await axios
      .post(`${BASE_URL}/txns/decline-withdrawal`, {
        id,
      })
      .then(() => {
        alert("Withdrawal declined successfully!");
      })
      .catch((err) => {
        alert("An error occured. Please try again later!");
      });
  }

  const columns = [
    {
      name: "Asset",
      selector: (row) => row.asset,
    },
    {
      name: "Network",
      selector: (row) => row.network,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Wallet Address",
      selector: (row) => row.walletAddress,
    },
    {
      name: "User",
      selector: (row) => row.user,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    pending && {
      name: "Actions",
      button: true,
      cell: (row, column) => (
        <div className="flex space-x-2 items-center">
          <button
            className="bg-green-500 py-1 px-4 w-[80px] rounded-md"
            onClick={() => approveWithdrawal(row.withdrawId)}
          >
            Approve
          </button>
          <button
            className="bg-red-600 py-1 px-4 w-[80px] rounded-md"
            onClick={() => {
              declineWithdrawal(row.withdrawId);
            }}
          >
            Decline
          </button>
        </div>
      ),
    },
  ];

  let data;

  if (pending) {
    data = pendingWithdrawals.map((withdraw, index) => {
      return {
        id: index,
        withdrawId: withdraw._id,
        asset: withdraw.asset.toUpperCase(),
        network: withdraw.network,
        amount: withdraw.amount,
        walletAddress: withdraw.walletAddress,
        status: withdraw.status,
        date: withdraw.createdAt.slice(0, 10),
        user: withdraw.user?.name,
      };
    });
  } else {
    data = withdrawals.map((withdraw, index) => {
      return {
        id: index,
        withdrawId: withdraw._id,
        asset: withdraw.asset.toUpperCase(),
        network: withdraw.network,
        amount: withdraw.amount,
        walletAddress: withdraw.walletAddress,
        status: withdraw.status,
        date: withdraw.createdAt.slice(0, 10),
        user: withdraw.user?.name,
      };
    });
  }
  return (
    <div>
      <DataTable pagination theme="default" columns={columns} data={data} />
    </div>
  );
};

export default Withdrawals;
