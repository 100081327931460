import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { BASE_URL } from "../../components/BaseUrl";
// import Deposit from "../../components/dashboard/deposit/deposit";
import SidebarComponent from "../../components/dashboard/Sidebar";
// import Withdraw from "../../components/dashboard/withdraw/withdraw";
import Loader from "../../components/Loader";
import {
  returnActiveInvestments,
  returnPendingDeposits,
  returnActivePools,
  returnPendingWithdrawals,
} from "../../utils/filter";
import Users from "../../components/dashboard/users";
import Deposits from "../../components/dashboard/deposits";
import Chart from "../../components/dashboard/Chart";

const Dashboard = () => {
  const [investments, setInvestments] = useState([]);
  const [activeInvestments, setActiveInvestments] = useState([]);
  const [pools, setPools] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [users, setUsers] = useState([]);
  const [pendingDeposits, setPendingDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [pendingWithdrawals, setPendingWithdrawals] = useState([]);
  const [activePools, setActivePools] = useState([]);
  // const [realTimeAdmin, setRealTimeAdmin] = useState(false);
  const { admin } = useContext(AuthContext);
  useEffect(() => {
    // ECHO USER
    // axios
    //   .get(`${BASE_URL}/admin-auth/echo?email=${admin.email}`)
    //   .then((res) => {
    //     console.log(res);
    //     setRealTimeAdmin(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // GET INVESTMENTS
    axios
      .get(`${BASE_URL}/txns/investments`)
      .then((res) => {
        setInvestments(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // GET DEPOSITS
    axios
      .get(`${BASE_URL}/txns/deposits`)
      .then((res) => {
        setDeposits(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // GET WITHDRAWALS
    axios
      .get(`${BASE_URL}/txns/withdrawals`)
      .then((res) => {
        setWithdrawals(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // GET POOLS
    axios
      .get(`${BASE_URL}/txns/pools`)
      .then((res) => {
        setPools(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // GET USERS
    axios
      .get(`${BASE_URL}/txns/users`)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    // FILTER INVESTMENTS
    if (investments) {
      const active = returnActiveInvestments(investments);
      setActiveInvestments(active);
    }

    // FILTER POOLS
    if (pools) {
      const active = returnActivePools(pools);
      setActivePools(active);
    }

    // FILTER DEPOSITS
    if (deposits) {
      const pending = returnPendingDeposits(deposits);
      setPendingDeposits(pending);
      console.log(deposits);
    }

    // FILTER WITHDRAWALS
    if (withdrawals) {
      const pending = returnPendingWithdrawals(withdrawals);
      setPendingWithdrawals(pending);
    }
  }, [investments, pools, deposits, withdrawals]);
  console.log(pendingDeposits);
  let initialValue = 0;
  const activeAmounts = activeInvestments.map((investment) => {
    return parseInt(investment.amount);
  });
  const amounts = investments.map((investment) => {
    return parseInt(investment.amount);
  });
  const activeInvestmentsSum = activeAmounts.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  const investmentsSum = amounts.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    initialValue
  );
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f]">
        <div className="p-4 border border-gray-600 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <div className="md:grid space-y-5 md:space-y-0 grid-cols-3 gap-4 mb-4">
            <div className="rounded bg-[#181b20] p-5">
              <h1 className="text-2xl font-medium text-gray-300">
                Investments
              </h1>
              <div className="mt-3 flex items-center justify-between">
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Active</h2>
                  <p className="mt-1 text-lg text-gray-400">
                    {activeInvestments.length}
                  </p>
                </div>
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Total</h2>
                  <p className="mt-2 text-lg text-gray-400">
                    {investments.length}
                  </p>
                </div>
              </div>
            </div>
            <div className="rounded bg-[#181b20] p-5">
              <div>
                <h1 className="text-2xl font-medium text-gray-300">Pools</h1>
              </div>
              <div className="mt-3 flex items-center justify-between">
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Active</h2>
                  <p className="mt-1 text-lg text-gray-400">
                    {activePools.length}
                  </p>
                </div>
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Total</h2>
                  <p className="mt-2 text-lg text-gray-400">{pools.length}</p>
                </div>
              </div>
            </div>
            <div className="rounded bg-[#181b20] p-5">
              <div>
                <h1 className="text-2xl font-medium text-gray-300">Deposits</h1>
              </div>
              <div className="mt-3 flex items-center justify-between">
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Pending</h2>
                  <p className="mt-1 text-lg text-gray-400">
                    {pendingDeposits.length}
                  </p>
                </div>
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Total</h2>
                  <p className="mt-2 text-lg text-gray-400">
                    {deposits.length}
                  </p>
                </div>
              </div>
            </div>
            <div className="rounded bg-[#181b20] p-5">
              <div>
                <h1 className="text-2xl font-medium text-gray-300">
                  Withdrawals
                </h1>
              </div>
              <div className="mt-3 flex items-center justify-between">
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Pending</h2>
                  <p className="mt-1 text-lg text-gray-400">
                    {pendingWithdrawals.length}
                  </p>
                </div>
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Total</h2>
                  <p className="mt-2 text-lg text-gray-400">
                    {withdrawals.length}
                  </p>
                </div>
              </div>
            </div>
            <div className="rounded bg-[#181b20] p-5">
              <div>
                <h1 className="text-2xl font-medium text-gray-300">Users</h1>
              </div>
              <div className="mt-3">
                <p className="mt-1 text-lg text-gray-400">{users.length}</p>
              </div>
            </div>
            <div className="rounded bg-[#181b20] p-5">
              <div>
                <h1 className="text-2xl font-medium text-gray-300">
                  Total invested amount
                </h1>
              </div>
              <div className="mt-3 flex items-center justify-between">
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">
                    Active investments
                  </h2>
                  <p className="mt-1 text-lg text-gray-400">
                    ${activeInvestmentsSum}
                  </p>
                </div>
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Total</h2>
                  <p className="mt-2 text-lg text-gray-400">
                    ${investmentsSum}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:grid space-y-5 md:space-y-0 grid-cols-2 gap-4 mb-4">
            <div className="rounded bg-[#181b20] p-5">
              <div className="flex items-center justify-between">
                <h1 className="text-2xl font-medium text-gray-300">Users</h1>
                <Link to="/users">
                  <button className="bg-[#b2ffce] py-1 w-full max-w-[80px] px-3 rounded-md hover:cursor-pointer">
                    View more
                  </button>
                </Link>
              </div>
              <div className="mt-5">
                <Users />
              </div>
            </div>
            <div className="rounded bg-[#181b20] px-5 py-5">
              <div className="flex items-center justify-between">
                <h1 className="text-2xl font-medium text-gray-300">Deposits</h1>
                <Link to="/deposits">
                  <button className="bg-[#b2ffce] py-1 w-full max-w-[80px] px-3 rounded-md hover:cursor-pointer">
                    View more
                  </button>
                </Link>
              </div>
              <div className="mt-5">
                <Deposits />
              </div>
            </div>
          </div>
          <div className="mb-4 rounded bg-[#181b20] text-gray-300">
            <h1 className="text-center font-medium py-7 text-3xl">
              Strategies
            </h1>
            <div className="md:grid space-y-5 md:space-y-0 grid-cols-3 gap-4 mb-4 fadeIn">
              {pools?.map((pool) => {
                return (
                  <div className="rounded bg-[#181b20] p-5">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <div>
                          <img
                            alt="manager"
                            className="w-14 h-14 rounded-full"
                            src={pool.image}
                          />
                        </div>
                        <div>
                          <h1 className="text-2xl font-medium text-gray-300">
                            {pool.name}
                          </h1>
                          <p className=" text-gray-400">{pool.manager}</p>
                        </div>
                      </div>
                      <Link to={`/strategy/${pool._id}`}>
                        <button className="w-full bg-[#b2ffce] text-gray-800 rounded-md px-4 py-1 outline-none">
                          View
                        </button>
                      </Link>
                    </div>
                    <div className="bg-gray-400 w-full h-[0.5px] mt-2"></div>
                    <div className="flex items-center justify-between mt-3">
                      <div>
                        <p className="text-sm text-gray-400">
                          Value Managed: <strong>${pool.valueManaged}</strong>
                        </p>
                        <p className="text-sm text-gray-400">
                          Risk Factor: {}
                          <span
                            className={`${
                              pool.riskFactor.split("/")[0] > 5
                                ? "text-red-500"
                                : "text-[#b2ffce]"
                            } `}
                          >
                            {pool.riskFactor}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-400">
                          3M Return:{" "}
                          <span className="text-[#b2ffce]">
                            +{pool.threeMonthsReturn}%
                          </span>
                        </p>
                        <p className="text-sm text-gray-400">
                          6M Return:{" "}
                          <strong className="text-[#b2ffce]">
                            +{pool.sixMonthsReturn}%
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Chart
                        yearly={pool.yearlyReturn}
                        monthly={pool.oneMonthReturn}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
