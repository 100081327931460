import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { useNavigate } from "react-router-dom";

createTheme("default", {
  text: {
    primary: "#fff",
    secondary: "#fff",
  },
  background: {
    default: "#181b20",
  },
});

const LeaderboardTable = () => {
  const [pools, setPools] = useState([]);
  const navigate = useNavigate();

  async function getPools() {
    try {
      const { data } = await axios.get(`${BASE_URL}/txns/pools`);
      setPools(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getPools();
  }, []);
  const columns = [
    {
      name: "Pool",
      selector: (row) => row.pool,
    },
    {
      name: "Managed",
      selector: (row) => row.managed,
      sortable: true,
    },
    {
      name: "1M",
      selector: (row) => row.perMonth,
      sortable: true,
    },
    {
      name: "3M",
      selector: (row) => row.perQuarter,
      sortable: true,
    },
    {
      name: "6M",
      selector: (row) => row.perSixMonths,
      sortable: true,
    },
    {
      name: "1Y",
      selector: (row) => row.perYear,
      sortable: true,
    },
    {
      name: "Risk",
      cell: (row, column) => (
        <div className="">
          <button
            className={`py-[3px] px-4 bg-gray-900 border text-xs ${
              row.risk.split("/")[0] >= 2
                ? "border-red-600 text-red-600"
                : "border-green-600 text-green-600"
            } rounded-xl`}
          >
            Risk: {row.risk}
          </button>
        </div>
      ),
    },
    {
      name: "Action",
      button: true,
      cell: (row, column) => (
        <div className="">
          <button
            className={`py-1 px-4 bg-[#b2ffce] text-gray-800 text-xs rounded-md`}
            onClick={() => {
              navigate(`/strategy/${row.poolId}`);
            }}
          >
            View
          </button>
        </div>
      ),
    },
  ];
  const data = pools.map((pool, index) => {
    if (pool.active === true) {
      return {
        id: index,
        pool: pool.name,
        poolId: pool._id,
        managed: pool.valueManaged,
        perMonth: pool.oneMonthReturn,
        perQuarter: pool.threeMonthsReturn,
        perSixMonths: pool.sixMonthsReturn,
        perYear: pool.yearlyReturn,
        risk: pool.riskFactor,
      };
    }
  });
  return (
    <div>
      <DataTable
        theme="default"
        columns={columns}
        data={data}
        pagination={true}
      />
    </div>
  );
};

export default LeaderboardTable;
