import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

createTheme("default", {
  text: {
    primary: "#fff",
    secondary: "#fff",
  },
  background: {
    default: "#181b20",
  },
});

const Coins = () => {
  const [coins, setCoins] = useState([]);

  async function getCoins() {
    try {
      const { data } = await axios.get(`${BASE_URL}/txns/coins`);
      setCoins(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCoins();
  }, []);

  async function deleteCoin(id) {
    await axios
      .delete(`${BASE_URL}/txns/coin/${id}`)
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Symbol",
      selector: (row) => row.symbol,
      sortable: true,
    },
    {
      name: "Networks",
      selector: (row) => row.networks,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => row.image,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Update",
      button: true,
      cell: (row, column) => (
        <div className="flex space-x-2 items-center">
          {/* <button
            className="bg-yellow-400 py-1 px-4 w-[80px] rounded-md"
            onClick={() => {
              navigate(`/coin/${row.coinId}`);
            }}
          >
            Update
          </button> */}
          <button
            className="bg-red-800 py-1 px-4 w-[80px] rounded-md"
            onClick={(e) => {
              deleteCoin(row.coinId);
            }}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  const data = coins.map((coin, index) => {
    return {
      id: index,
      name: coin.name,
      coinId: coin._id,
      symbol: coin.symbol,
      networks: coin.networks,
      image: coin.image,
      price: coin.price,
    };
  });
  return (
    <div>
      <DataTable pagination theme="default" columns={columns} data={data} />
    </div>
  );
};

export default Coins;
