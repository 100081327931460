import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import SidebarComponent from "../../components/dashboard/Sidebar";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../components/BaseUrl";
import CircularProgress from "@mui/material/CircularProgress";
// import InputFields from "../../components/dashboard/WalletInputs";

export default function NewCoin() {
  const { admin } = useContext(AuthContext);
  const [networks, setNetworks] = useState("");
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState("");
  const [fields, setFields] = useState([{ network: "", walletAddress: "" }]);

  const [defaultCoin, setDefaultCoin] = useState({
    name: "",
    symbol: "",
    networks: [],
    wallets: {},
  });

  function handleWalletInputChange(index, event) {
    const newFields = [...fields];
    newFields[index][event.target.name] = event.target.value;
    setFields(newFields);
  }

  function handleAddField() {
    setFields([...fields, { network: "", walletAddress: "" }]);
  }

  function handleRemoveField(index) {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  }
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault(); // prevent tab from moving focus to next element
      const newNetwork = networks.charAt(0).toUpperCase() + networks.slice(1);
      console.log(newNetwork);

      setDefaultCoin({
        ...defaultCoin,
        networks: [...defaultCoin.networks, newNetwork],
      });
      setNetworks("");
      console.log(defaultCoin.networks);
    }
  };
  const handleInputChange = (event) => {
    setNetworks(event.target.value);
  };

  async function newCoin(e) {
    setLoading(true);
    e.preventDefault();
    const data = {
      name: defaultCoin.name,
      symbol: defaultCoin.symbol,
      networks: defaultCoin.networks,
      file: doc,
      wallets: fields,
    };
    console.log(data);
    const formdata = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (key === "networks" || key === "wallets") {
        formdata.append(key, JSON.stringify(value));
      } else {
        formdata.append(key, value);
      }
    }
    fetch(`${BASE_URL}/txns/coin/new`, {
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.ok) {
          alert("Coin created successfully");
          setLoading(false);
        }
        console.log(res);
      })
      .catch((error) => {
        console.log(error.response.data);
        alert("An error occured, please try again later");
        setLoading(false);
      });
  }

  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          <div>
            <div>
              <h1 className="text-gray-400 text-xl font-medium">New Coin</h1>
            </div>
            <form onSubmit={newCoin} className="">
              <div className="md:grid grid-cols-3 gap-10 mt-10 space-y-4">
                <div>
                  <label htmlFor="name" className="text-gray-300 text-sm">
                    Name
                  </label>
                  <input
                    name="name"
                    onChange={(e) =>
                      setDefaultCoin({ ...defaultCoin, name: e.target.value })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="symbol" className="text-gray-300 text-sm">
                    Symbol
                  </label>
                  <input
                    name="symbol"
                    onChange={(e) =>
                      setDefaultCoin({
                        ...defaultCoin,
                        symbol: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="document" className="text-gray-300 text-sm">
                    Coin Image *
                  </label>
                  <input
                    name="document"
                    onChange={(e) => {
                      setDoc(e.target.files[0]);
                    }}
                    type="file"
                    required
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="networks" className="text-gray-300 text-sm">
                    Networks
                    <p className="text-xs">Separate with the tab button</p>
                  </label>
                  <input
                    value={networks}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    name="networks"
                    className="bg-[#181b20] capitalize w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                  <div className="flex items-center space-x-3">
                    {defaultCoin.networks.length > 0 &&
                      defaultCoin.networks.map((singleNetwork, index) => {
                        return (
                          <div
                            onClick={() => {
                              defaultCoin.networks.splice(index, 1);
                              console.log(defaultCoin.networks);
                            }}
                            key={index}
                            className="box"
                          >
                            {singleNetwork}
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div>
                  <label htmlFor="wallets" className="text-gray-300 text-sm">
                    Wallet Addresses
                  </label>
                  <form>
                    {fields.map((field, index) => (
                      <div
                        key={index}
                        className="space-x-2 flex items-center py-4 w-full"
                      >
                        <div>
                          <p className="text-gray-300 text-sm">Network</p>
                          <input
                            type="text"
                            name="network"
                            value={field.network}
                            onChange={(e) => handleWalletInputChange(index, e)}
                            className="bg-[#181b20] py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                          />
                        </div>
                        <div>
                          <p className="text-gray-300 text-sm">
                            Wallet address
                          </p>
                          <input
                            type="text"
                            name="walletAddress"
                            value={field.walletAddress}
                            onChange={(e) => handleWalletInputChange(index, e)}
                            className="bg-[#181b20] py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                          />
                        </div>
                        <button
                          className="bg-red-400 text-white h-8 px-4 text-sm rounded-md"
                          type="button"
                          onClick={() => handleRemoveField(index)}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      className="bg-yellow-400 text-white px-4 rounded-md text-sm py-1"
                      type="button"
                      onClick={handleAddField}
                    >
                      Add wallet
                    </button>
                  </form>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="md:w-[150px] mt-4 w-full bg-[#b2ffce] px-5 py-2 rounded-md font-medium"
                >
                  {loading ? (
                    <CircularProgress color="inherit" size="25px" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
