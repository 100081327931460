import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import SidebarComponent from "../../components/dashboard/Sidebar";
import Loader from "../../components/Loader";
import axios from "axios";
import { BASE_URL } from "../../components/BaseUrl";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const UpdateStrategyFAQ = () => {
  const { id } = useParams();
  const { admin } = useContext(AuthContext);
  const [faq, setFaq] = useState(null);
  const [title, setTitle] = useState(faq?.title);
  const [body, setBody] = useState(faq?.body);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  async function updateDetails(userTitle, userBody) {
    try {
      setLoading(true);
      const res = await axios.post(`${BASE_URL}/faq/update-strategy/${id}`, {
        title: userTitle,
        body: userBody,
      });
      if (res.data.status === "success") {
        setSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setSuccess(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    axios
      .get(`${BASE_URL}/faq/single-strategy/${id}`)
      .then((res) => {
        setFaq(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          {faq && (
            <div>
              <div>
                <h1 className="text-gray-400 text-xl font-medium">Settings</h1>
              </div>
              <form className="md:grid grid-cols-3 gap-10 mt-10">
                <div>
                  <label htmlFor="title" className="text-gray-300 text-sm">
                    Title
                  </label>
                  <input
                    name="title"
                    defaultValue={faq.title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="body" className="text-gray-300 text-sm">
                    Body
                  </label>
                  <input
                    name="body"
                    defaultValue={faq.body}
                    onChange={(e) => setBody(e.target.value)}
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      updateDetails(title, body);
                    }}
                    type="submit"
                    className="md:w-1/2 w-full bg-[#b2ffce] px-5 py-2 rounded-md font-medium"
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size="25px" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          )}
          {success === true && (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="success">
                FAQ has been updated successfully
              </Alert>
            </Snackbar>
          )}
          {success === false && (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="error">
                An error occured, please try again later
              </Alert>
            </Snackbar>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateStrategyFAQ;
