import React, { useContext } from "react";
import SidebarComponent from "../../components/dashboard/Sidebar";
import { AuthContext } from "../../context/authContext";
import Loader from "../../components/Loader";
import Withdrawals from "../../components/dashboard/WithdrawHistory";

const WithdrawalPage = () => {
  const { admin } = useContext(AuthContext);
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          <div>
            <h1 className="font-medium text-3xl py-4 tracking-wider text-gray-300">
              Withdrawals
            </h1>
          </div>
          <div>
            <Withdrawals />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalPage;
