import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import SidebarComponent from "../../components/dashboard/Sidebar";
import Loader from "../../components/Loader";
import axios from "axios";
import { BASE_URL } from "../../components/BaseUrl";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const UpdateYield = () => {
  const { id } = useParams();
  const { admin } = useContext(AuthContext);
  const [fixedYield, setFixedYield] = useState(null);
  const [values, setValues] = useState({
    name: "",
    price: "",
    symbol: "",
    apy: "",
    image: "",
    priceChange: "",
    pricePercentageChange: "",
  });
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  async function updateDetails() {
    try {
      setLoading(true);
      const data = {
        name: values.name ? values.name : fixedYield.name,
        price: values.price ? values.price : fixedYield.price,
        symbol: values.symbol ? values.symbol : fixedYield.symbol,
        apy: values.apy ? values.apy : fixedYield.apy,
        image: values.image ? values.image : fixedYield.image,
        priceChange: values.priceChange
          ? values.priceChange
          : fixedYield.priceChange,
        pricePercentageChange: values.pricePercentageChange
          ? values.pricePercentageChange
          : fixedYield.pricePercentageChange,
      };
      const res = await axios.post(
        `${BASE_URL}/txns/fixed-yield/update/${id}`,
        data
      );
      if (res.data.status === "success") {
        setSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setSuccess(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    axios
      .get(`${BASE_URL}/txns/fixed-yield/${id}`)
      .then((res) => {
        setFixedYield(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          {fixedYield && (
            <div>
              <div>
                <h1 className="text-gray-400 text-xl font-medium">
                  Update Yield
                </h1>
              </div>
              <form className="md:grid grid-cols-3 gap-10 mt-10">
                <div>
                  <label htmlFor="name" className="text-gray-300 text-sm">
                    Name
                  </label>
                  <input
                    name="name"
                    defaultValue={fixedYield.name}
                    onChange={(e) =>
                      setValues({ ...values, name: e.target.value })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="symbol" className="text-gray-300 text-sm">
                    Symbol
                  </label>
                  <input
                    name="symbol"
                    defaultValue={fixedYield.symbol}
                    onChange={(e) =>
                      setValues({ ...values, symbol: e.target.value })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="price" className="text-gray-300 text-sm">
                    Price
                  </label>
                  <input
                    name="price"
                    defaultValue={fixedYield.price}
                    onChange={(e) =>
                      setValues({ ...values, price: e.target.value })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="image" className="text-gray-300 text-sm">
                    Yield mage
                  </label>
                  <input
                    name="image"
                    defaultValue={fixedYield.image}
                    onChange={(e) =>
                      setValues({ ...values, image: e.target.value })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="apy" className="text-gray-300 text-sm">
                    APY
                  </label>
                  <input
                    name="apy"
                    defaultValue={fixedYield.apy}
                    onChange={(e) =>
                      setValues({ ...values, apy: e.target.value })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="priceChange"
                    className="text-gray-300 text-sm"
                  >
                    Price Change
                  </label>
                  <input
                    name="priceChange"
                    defaultValue={fixedYield.priceChange}
                    onChange={(e) =>
                      setValues({ ...values, priceChange: e.target.value })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label
                    htmlFor="pricePercentageChange"
                    className="text-gray-300 text-sm"
                  >
                    Price Percentage Change
                  </label>
                  <input
                    name="pricePercentageChange"
                    defaultValue={fixedYield.pricePercentageChange}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        pricePercentageChange: e.target.value,
                      })
                    }
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
              </form>
              <div className="mt-7">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    updateDetails();
                  }}
                  className="md:w-1/4 w-full bg-[#b2ffce] px-5 py-2 rounded-md font-medium"
                >
                  {loading ? (
                    <CircularProgress color="inherit" size="25px" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          )}
          {success === true && (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="success">
                Fixed yield has been updated successfully
              </Alert>
            </Snackbar>
          )}
          {success === false && (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="error">
                An error occured, please try again later
              </Alert>
            </Snackbar>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateYield;
