import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
// import DepositHistory from "./pages/dashboard/deposit-history";
import Investment from "./pages/dashboard/investment";
// import InvestmentHistory from "./pages/dashboard/investment-history";
import SingleKyc from "./pages/dashboard/single-kyc";
import Leaderboard from "./pages/dashboard/leaderboard";
import Pools from "./pages/dashboard/pools";
// import Withdraw from "./pages/dashboard/withdraw";
// import WithdrawHistory from "./pages/dashboard/withdraw-history";
import Login from "./pages/login";
import Signup from "./pages/signup";
import PrivateRoutes from "./components/PrivateRoutes";
import Pool from "./pages/dashboard/pool";
import UsersPage from "./pages/dashboard/users";
import UserPage from "./pages/dashboard/user";
import PendingDeposit from "./pages/dashboard/pendingDeposit";
import DepositsPage from "./pages/dashboard/deposits";
import PendingWithdrawal from "./pages/dashboard/pendingWithdrawals";
import WithdrawalPage from "./pages/dashboard/withdrawals";
import PendingKyc from "./pages/dashboard/kyc";
import AllKyc from "./pages/dashboard/allKyc";
import NewPool from "./pages/dashboard/new-pool";
import UpdateFAQ from "./pages/dashboard/update-faq";
import FAQPage from "./pages/dashboard/faq";
import NewFaq from "./pages/dashboard/new-faq";
import NewStrategyFaq from "./pages/dashboard/new-strategy-faq";
import StrategyFAQPage from "./pages/dashboard/strategy-faq";
import UpdateStrategyFAQ from "./pages/dashboard/update-strategy-faq";
import NewCoin from "./pages/dashboard/new-coin";
import AllCoins from "./pages/dashboard/all-coins";
import SingleCoin from "./pages/dashboard/single-coin";
import NewFixedYield from "./pages/dashboard/new-fixed-yield";
import AllYields from "./pages/dashboard/all-yields";
import UpdateYield from "./pages/dashboard/update-yield";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Dashboard />} />
          {/* STRATEGIES */}
          <Route path="/strategies" element={<Pools />} />
          <Route path="/strategy/new" element={<NewPool />} />
          <Route path="/new-strategy" element={<NewStrategyFaq />} />
          <Route path="/strategy/:id" element={<Pool />} />

          {/* USERS  */}
          <Route path="/users" element={<UsersPage />} />
          <Route path="/user/:id" element={<UserPage />} />

          {/* KYC  */}
          <Route path="/kyc/:id" element={<SingleKyc />} />
          <Route path="/pending-kycs" element={<PendingKyc />} />
          <Route path="/kycs" element={<AllKyc />} />

          {/* INVESTMENTS  */}
          <Route path="/investment" element={<Investment />} />

          {/* FAQ  */}
          <Route path="/strategy-faqs" element={<StrategyFAQPage />} />
          <Route path="/update-faq/:id" element={<UpdateFAQ />} />
          <Route path="/update-strategy/:id" element={<UpdateStrategyFAQ />} />
          <Route path="/faqs" element={<FAQPage />} />
          <Route path="/new-faq" element={<NewFaq />} />

          {/* FIXED YIELD  */}
          <Route path="/fixed-yield/new" element={<NewFixedYield />} />
          <Route path="/fixed-yields" element={<AllYields />} />
          <Route path="/fixed-yield/:id" element={<UpdateYield />} />

          {/* COINS  */}
          <Route path="/new-coin" element={<NewCoin />} />
          <Route path="/coins" element={<AllCoins />} />
          <Route path="/coin/:id" element={<SingleCoin />} />

          {/* DEPOSITS  */}
          <Route path="/pending-deposits" element={<PendingDeposit />} />
          <Route path="/deposits" element={<DepositsPage />} />

          {/* WITHDRAWALS  */}
          <Route path="/pending-withdrawals" element={<PendingWithdrawal />} />
          <Route path="/withdrawals" element={<WithdrawalPage />} />

          {/* LEADERBOARD  */}
          <Route path="/leaderboard" element={<Leaderboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
