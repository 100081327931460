import { useAuthContext } from './useAuthContext'

const useLogout = () => {
    const {dispatch} = useAuthContext()
    function logout() {
        // remove user from storage
        localStorage.removeItem("user")

        // DISPATCH LOGOUT ACTIONS
        dispatch({type: "LOGOUT"})
    }
    return {logout}
}

export default useLogout