import { createContext, useReducer, useEffect } from "react";

export const AuthContext = createContext();

export function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        admin: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        admin: null,
      };
    default:
      return state;
  }
}

export const AuthContextProvider = ({ children }) => {
  const _admin =
    localStorage.getItem("admin") && JSON.parse(localStorage.getItem("admin"));
  const token = localStorage.getItem("access");
  const [state, dispatch] = useReducer(authReducer, {
    admin: _admin,
    token,
  });
  useEffect(() => {
    if (_admin) {
      dispatch({ type: "LOGIN", payload: _admin });
    }
  }, []);

  console.log("AuthContextState: ", _admin);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
