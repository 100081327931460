import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { useNavigate } from "react-router-dom";

createTheme("default", {
  text: {
    primary: "#fff",
    secondary: "#fff",
  },
  background: {
    default: "#181b20",
  },
});

const Users = ({ page = false }) => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  async function getUsers() {
    try {
      const { data } = await axios.get(`${BASE_URL}/txns/users`);
      setUsers(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getUsers();
  }, []);

  async function deleteUser(id) {
    await axios
      .delete(`${BASE_URL}/txns/user/${id}`)
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Tier",
      selector: (row) => row.tier,
      sortable: true,
    },
    {
      name: "Referral Code",
      selector: (row) => row.ref,
      sortable: true,
    },
    {
      name: "Verification Status",
      selector: (row) => row.verified,
      sortable: true,
    },
    page && {
      name: "Update",
      button: true,
      cell: (row, column) => (
        <div className="flex space-x-2 items-center">
          <button
            className="bg-yellow-400 py-1 px-4 w-[80px] rounded-md"
            onClick={() => {
              navigate(`/user/${row.userId}`);
            }}
          >
            Update
          </button>
          <button
            className="bg-red-800 py-1 px-4 w-[80px] rounded-md"
            onClick={(e) => {
              deleteUser(row.userId);
            }}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  const data = users.map((user, index) => {
    return {
      id: index,
      userId: user._id,
      name: user.name,
      email: user.email,
      tier: user._type,
      ref: user.referralCode,
      verified: user.verified.toString(),
    };
  });
  return (
    <div>
      <DataTable pagination theme="default" columns={columns} data={data} />
    </div>
  );
};

export default Users;
