import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { returnPendingKycs } from "../../utils/filter";

createTheme("default", {
  text: {
    primary: "#fff",
    secondary: "#fff",
  },
  background: {
    default: "#181b20",
  },
});

const KycList = ({ pending = false }) => {
  const [kycs, setKycs] = useState([]);
  const [loading, setLoading] = useState(null);
  const [pendingKycs, setPendingKycs] = useState([]);

  async function getKycs() {
    try {
      setLoading(true);
      const { data } = await axios.get(`${BASE_URL}/txns/kycs`);
      setKycs(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    getKycs();
  }, []);

  useEffect(() => {
    // FILTER KYCS
    if (kycs) {
      const pending = returnPendingKycs(kycs);
      setPendingKycs(pending);
      console.log(kycs);
      console.log(pending);
    }
  }, [kycs]);

  async function approveKyc(id) {
    await axios
      .post(`${BASE_URL}/txns/verify-kyc`, {
        id,
      })
      .then(() => {
        alert("Kyc approved successfully!");
      })
      .catch((err) => {
        alert("An error occured. Please try again later!");
      });
  }

  async function declineKyc(id) {
    axios
      .post(`${BASE_URL}/txns/decline-kyc`, {
        id,
      })
      .then(() => {
        alert("Kyc declined successfully!");
        axios
          .delete(`${BASE_URL}/txns/kyc/${id}`)
          .then(() => {
            window.location.reload(true);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        alert("An error occured. Please try again later!");
      });
  }

  const columns = [
    {
      name: "First Name",
      selector: (row) => row.fName,
    },
    {
      name: "Last Name",
      selector: (row) => row.lName,
    },
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "State",
      selector: (row) => row.state,
    },
    {
      name: "Document Type",
      selector: (row) => row.docType,
    },
    {
      name: "Document",
      button: true,
      cell: (row, column) => (
        <div className="flex space-x-2 items-center">
          <a
            href={`${row.doc}`}
            className="bg-green-500 py-1 px-4 w-[80px] rounded-md"
          >
            Document
          </a>
        </div>
      ),
    },
    {
      name: "User",
      selector: (row) => row.user,
    },
    // {
    //   name: "Date",
    //   selector: (row) => row.date,
    // },
    pending && {
      name: "Actions",
      button: true,
      cell: (row, column) => (
        <div className="flex space-x-2 items-center">
          <button
            className="bg-green-500 py-1 px-4 w-[80px] rounded-md"
            onClick={() => {
              approveKyc(row.kycId);
            }}
          >
            Approve
          </button>
          <button
            className="bg-red-600 py-1 px-4 w-[80px] rounded-md"
            onClick={() => {
              declineKyc(row.kycId);
            }}
          >
            Decline
          </button>
        </div>
      ),
    },
  ];

  let data;
  if (loading === false && kycs.length > 0) {
    if (pending) {
      data = pendingKycs.map((kyc, index) => {
        console.log(kyc);
        return {
          id: index,
          kycId: kyc._id,
          fName: kyc.firstName,
          lName: kyc.lastName,
          country: kyc.country,
          state: kyc.state,
          docType: kyc.documentType,
          // date: kyc?.createdAt.slice(0, 10),
          user: kyc.user?.name,
          doc: kyc.document,
        };
      });
    } else {
      console.log(kycs);
      data = kycs.map((kyc, index) => {
        console.log(kyc);
        return {
          id: index,
          kycId: kyc._id,
          fName: kyc.firstName,
          lName: kyc.lastName,
          country: kyc.country,
          state: kyc.state,
          docType: kyc.documentType,
          // date: kyc?.createdAt.slice(0, 10),
          user: kyc.user?.name,
          doc: kyc.document,
        };
      });
    }
  }

  return (
    <div>
      <DataTable pagination theme="default" columns={columns} data={data} />
    </div>
  );
};

export default KycList;
