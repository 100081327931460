import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { useNavigate } from "react-router-dom";

createTheme("default", {
  text: {
    primary: "#fff",
    secondary: "#fff",
  },
  background: {
    default: "#181b20",
  },
});

const FAQList = () => {
  const [list, setList] = useState([]);
  const navigate = useNavigate();

  async function getFaqs() {
    try {
      const { data } = await axios.get(`${BASE_URL}/faq`);
      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getFaqs();
  }, []);

  async function deleteFaq(id) {
    await axios
      .delete(`${BASE_URL}/faq/delete/${id}`)
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Body",
      selector: (row) => row.body,
      sortable: true,
    },
    {
      name: "Update",
      button: true,
      cell: (row, column) => (
        <div className="flex space-x-2 items-center">
          <button
            className="bg-yellow-400 py-1 px-4 w-[80px] rounded-md"
            onClick={() => {
              navigate(`/update-faq/${row.faqId}`);
            }}
          >
            Update
          </button>
          <button
            className="bg-red-800 py-1 px-4 w-[80px] rounded-md"
            onClick={(e) => {
              deleteFaq(row.faqId);
            }}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  const data = list.map((singleFaq, index) => {
    return {
      id: index,
      faqId: singleFaq._id,
      title: singleFaq.title,
      body: `${singleFaq.body.slice(0, 60)}...`,
    };
  });
  return (
    <div>
      <DataTable pagination theme="default" columns={columns} data={data} />
    </div>
  );
};

export default FAQList;
