import React from "react";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  Area,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { format, parseISO, subDays } from "date-fns";

const Chart = ({ monthly, yearly, boxHeight }) => {
  const data = [];
  for (let num = 90; num >= 0; num--) {
    data.push({
      date: subDays(new Date(), num).toISOString().substring(0, 10),
      value: Math.random() * (yearly - monthly + 1) + 4,
    });
  }
  return (
    <ResponsiveContainer width="100%" height={boxHeight ? boxHeight : 200}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#b2ffce" stopOpacity={0.4} />
            <stop offset="75%" stopColor="#b2ffce" stopOpacity={0.05} />
          </linearGradient>
        </defs>

        <Area dataKey="value" stroke="#808080" fill="url(#color)" />

        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tickFormatter={(str) => {
            const date = parseISO(str);
            if (date.getDate() % 7 === 0) {
              return format(date, "MMM, d");
            }
            return "";
          }}
        />

        {/* <YAxis
          dataKey="value"
          axisLine={false}
          tickLine={false}
          tickCount={8}
          tickFormatter={(number) => `$${number.toFixed(2)}`}
        /> */}

        <Tooltip content={<CustomTooltip />} />

        <CartesianGrid opacity={0.1} vertical={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

function CustomTooltip({ active, payload, label }) {
  if (active) {
    return (
      <div className="tooltip">
        <h4>{format(parseISO(label), "eeee, d, MMM, yyyy")}</h4>
        <p>Performance: {payload[0].value.toFixed(2)}%</p>
      </div>
    );
  }
  return null;
}

export default Chart;
