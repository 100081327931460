import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Loader from "../../components/Loader";
import SidebarComponent from "../../components/dashboard/Sidebar";
import { AuthContext } from "../../context/authContext";
import CircularProgress from "@mui/material/CircularProgress";
import { BASE_URL } from "../../components/BaseUrl";

const NewFixedYield = () => {
  const { admin } = useContext(AuthContext);
  const [cryptoList, setCryptoList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState("");
  const [defaultFields, setDefaultFields] = useState({
    name: "",
    symbol: "",
    apy: "",
  });

  useEffect(() => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en"
      )
      .then((response) => {
        setCryptoList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  async function createFixedYield(e) {
    setLoading(true);
    e.preventDefault();
    const data = {
      yieldName: defaultFields.name,
      yieldSymbol: defaultFields.symbol,
      yieldApy: defaultFields.apy,
      file: doc,
    };
    const formdata = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formdata.append(key, value);
    }
    fetch(`${BASE_URL}/txns/fixed-yield/new`, {
      method: "POST",
      body: formdata,
    })
      .then((res) => {
        if (res.ok) {
          alert("Fixed yield created successfully");
          setLoading(false);
        }
        console.log(res);
      })
      .catch((error) => {
        console.log(error.response.data);
        alert("An error occured, please try again later");
        setLoading(false);
      });
  }
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          <div>
            <div>
              <h1 className="text-gray-400 text-xl font-medium">
                New Fixed Yield
              </h1>
            </div>
            <form onSubmit={createFixedYield}>
              <div className="md:grid grid-cols-3 gap-10 mt-10">
                <div>
                  <label htmlFor="name" className="text-gray-300 text-sm">
                    Name
                  </label>
                  <select
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                    onChange={(e) => {
                      setDefaultFields({
                        ...defaultFields,
                        name: e.target.value,
                      });
                    }}
                  >
                    {cryptoList?.map((crypto) => {
                      return (
                        <option key={crypto.id} value={crypto.id}>
                          {crypto.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <label htmlFor="symbol" className="text-gray-300 text-sm">
                    Symbol
                  </label>
                  <input
                    onChange={(e) => {
                      setDefaultFields({
                        ...defaultFields,
                        symbol: e.target.value,
                      });
                    }}
                    name="symbol"
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="apy" className="text-gray-300 text-sm">
                    APY
                  </label>
                  <input
                    onChange={(e) => {
                      setDefaultFields({
                        ...defaultFields,
                        apy: e.target.value,
                      });
                    }}
                    name="apy"
                    required
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="document" className="text-gray-300 text-sm">
                    Yield Image *
                  </label>
                  <input
                    name="document"
                    onChange={(e) => {
                      setDoc(e.target.files[0]);
                    }}
                    type="file"
                    required
                    className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="md:w-[150px] mt-4 w-full bg-[#b2ffce] px-5 py-2 rounded-md font-medium"
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size="25px" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFixedYield;
