import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import useLogout from "../../hooks/useLogout";

const SidebarComponent = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [drop, setDrop] = useState(false);
  const [dropOne, setDropOne] = useState(false);
  const [dropTwo, setDropTwo] = useState(false);
  const [dropThree, setDropThree] = useState(false);
  const [dropFour, setDropFour] = useState(false);
  const [dropFive, setDropFive] = useState(false);
  const [dropSix, setDropSix] = useState(false);
  const [dropSeven, setDropSeven] = useState(false);
  const [dropEight, setDropEight] = useState(false);
  const [dropNine, setDropNine] = useState(false);
  const { logout } = useLogout();
  function toggle() {
    setOpen(!open);
  }

  function toggleShow() {
    setShow(!show);
  }
  return (
    <div>
      <nav className="fixed top-0 z-20 w-full bg-[#181b20] border-b-[0.5px] border-gray-600 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-5 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                className="relative w-10 h-10 sm:hidden text-gray-500 rounded-sm focus:outline-none"
                onClick={() => setOpen(!open)}
              >
                <span className="sr-only">Open main menu</span>
                <div className="absolute block w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                  <span
                    aria-hidden="true"
                    className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                      open ? "rotate-45" : "-translate-y-1.5"
                    }`}
                  ></span>
                  <span
                    aria-hidden="true"
                    className={`block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out ${
                      open && "opacity-0"
                    }`}
                  ></span>
                  <span
                    aria-hidden="true"
                    className={`block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out ${
                      open ? "-rotate-45" : "translate-y-1.5"
                    }`}
                  ></span>
                </div>
              </button>
              <a
                href="https://algoostrategies.com"
                className="flex ml-2 md:mr-24"
              >
                <img
                  src={logo}
                  className="h-8 mr-3"
                  alt="Algoo Strategies Logo"
                />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap text-gray-300 ">
                  Algoo Strategies
                </span>
              </a>
            </div>
            <div className="flex items-center relative">
              <div className="flex items-center ml-3 md:space-x-5">
                <div>
                  <button
                    onClick={toggleShow}
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full"
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user"
                  >
                    <span className="sr-only">Open user menu</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-8 h-8 text-gray-400 rounded-full"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  className={`z-50 ${
                    show ? "absolute top-8 right-0 duration-500" : "hidden"
                  } my-4 text-base list-none bg-[#0a0c0f] shadow-lg divide-y divide-gray-600 rounded dark:bg-gray-700 dark:divide-gray-600`}
                  id="dropdown-user"
                >
                  <div className="px-4 py-3" role="none">
                    <p className="text-sm text-gray-300 capitalize" role="none">
                      {user.name}
                    </p>
                    <p
                      className="text-sm font-medium text-gray-400 truncate"
                      role="none"
                    >
                      {user.email}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    <li>
                      <Link
                        to="/profile"
                        className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 duration-500"
                        role="menuitem"
                      >
                        Referral
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/blog"
                        className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 duration-500"
                        role="menuitem"
                      >
                        <div className="flex items-center justify-between">
                          <p>Blog</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-3 h-3 text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                            />
                          </svg>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/wiki"
                        className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 duration-500"
                        role="menuitem"
                      >
                        <div className="flex items-center justify-between">
                          <p>Wiki</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-3 h-3 text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                            />
                          </svg>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/profile"
                        role="menuitem"
                        className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 duration-500"
                      >
                        Profile
                      </Link>
                    </li>
                    <li>
                      <div
                        onClick={logout}
                        role="menuitem"
                        className="block px-4 py-2 text-sm text-red-400 hover:bg-gray-700 duration-500"
                      >
                        Sign out
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-10 w-64 h-screen pt-28 transition-transform duration-500 ${
          open ? "translate-x-0" : "-translate-x-full"
        } bg-[#181b20] border-r-[0.5px] border-gray-600 sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-[#181b20] dark:bg-gray-800">
          <ul className="space-y-2">
            <li>
              <Link to="/">
                <div className="flex items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                    />
                  </svg>
                  <span className="ml-3">Dashboard</span>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/users">
                <div className="flex items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                    />
                  </svg>

                  <span className="ml-3">Users</span>
                </div>
              </Link>
            </li>
            <li>
              <div
                onClick={() => {
                  setDrop(!drop);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                  />
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Strategies
                </span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${drop ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/strategy/new"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    New Strategy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/strategies"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition rounded-lg pl-11 group hover:bg-gray-700 duration-500"
                  >
                    All strategies
                  </Link>
                </li>
                <li>
                  <Link
                    to="/leaderboard"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    Leaderboard
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <div
                onClick={() => {
                  setDropNine(!dropNine);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Fixed Yields
                </span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${dropNine ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/fixed-yield/new"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    New Fixed Yield
                  </Link>
                </li>
                <li>
                  <Link
                    to="/fixed-yields"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition rounded-lg pl-11 group hover:bg-gray-700 duration-500"
                  >
                    All Fixed Yields
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/fixed-yields/leaderboard"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    Leaderboard
                  </Link>
                </li> */}
              </ul>
            </li>
            <li>
              <div
                onClick={() => {
                  setDropEight(!dropEight);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                  />
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">Coins</span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${dropEight ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/new-coin"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    New Coin
                  </Link>
                </li>
                <li>
                  <Link
                    to="/coins"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition rounded-lg pl-11 group hover:bg-gray-700 duration-500"
                  >
                    All coins
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <div
                onClick={() => {
                  setDropOne(!dropOne);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Investments
                </span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${dropOne ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/investment"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition rounded-lg pl-11 group hover:bg-gray-700 duration-500"
                  >
                    Current Investments
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <div
                onClick={() => {
                  setDropTwo(!dropTwo);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                  />
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">Deposits</span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${dropTwo ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/pending-deposits"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    Pending Deposits
                  </Link>
                </li>
                <li>
                  <Link
                    to="/deposits"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    All Deposits
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <div
                onClick={() => {
                  setDropThree(!dropThree);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                  />
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Withdrawals
                </span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${dropThree ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/pending-withdrawals"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    Pending Withdrawals
                  </Link>
                </li>
                <li>
                  <Link
                    to="/withdrawals"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    All Withdrawal
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <div
                onClick={() => {
                  setDropFour(!dropFour);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                  />
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">KYC</span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${dropFour ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/pending-kycs"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    Pending Verifications
                  </Link>
                </li>
                <li>
                  <Link
                    to="/kycs"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    All Kycs
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <div
                onClick={() => {
                  setDropFive(!dropFive);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">FAQ</span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div
                onClick={() => {
                  setDropSix(!dropSix);
                }}
                className={`flex hover:cursor-pointer items-center px-4 py-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700 ${
                  dropFive ? "block" : "hidden"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">
                  General FAQ
                </span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${dropSix ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/faqs"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    All FAQ's
                  </Link>
                </li>
                <li>
                  <Link
                    to="/new-faq"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    New FAQ
                  </Link>
                </li>
              </ul>
              <div
                onClick={() => {
                  setDropSeven(!dropSeven);
                }}
                className={`flex hover:cursor-pointer items-center px-4 py-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700 ${
                  dropFive ? "block" : "hidden"
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Strategies FAQ
                </span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${dropSeven ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/strategy-faqs"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    All Strategy FAQ's
                  </Link>
                </li>
                <li>
                  <Link
                    to="/new-strategy"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    New FAQ
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default SidebarComponent;
