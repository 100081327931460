import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
import { returnPendingDeposits } from "../../utils/filter";

createTheme("default", {
  text: {
    primary: "#fff",
    secondary: "#fff",
  },
  background: {
    default: "#181b20",
  },
});

const Deposits = ({ page = false, pending = false }) => {
  const [deposits, setDeposits] = useState([]);
  const [pendingDeposits, setPendingDeposits] = useState([]);

  async function getDeposits() {
    try {
      const { data } = await axios.get(`${BASE_URL}/txns/deposits`);
      setDeposits(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getDeposits();
  }, []);

  useEffect(() => {
    // FILTER DEPOSITS
    if (deposits) {
      const pending = returnPendingDeposits(deposits);
      setPendingDeposits(pending);
    }
  }, [deposits]);

  async function approveDeposit(id) {
    await axios
      .post(`${BASE_URL}/txns/approve-deposit`, {
        id,
      })
      .then(() => {
        alert("Deposit approved successfully!");
      })
      .catch((err) => {
        alert("An error occured. Please try again later!");
      });
  }

  async function declineDeposit(id) {
    await axios
      .post(`${BASE_URL}/txns/decline-deposit`, {
        id,
      })
      .then(() => {
        alert("Deposit declined successfully!");
      })
      .catch((err) => {
        alert("An error occured. Please try again later!");
      });
  }

  const columns = [
    {
      name: "Asset",
      selector: (row) => row.asset,
    },
    {
      name: "Network",
      selector: (row) => row.network,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
    },
    {
      name: "Transaction ID",
      selector: (row) => row.txnId,
    },
    {
      name: "User",
      selector: (row) => row.user,
    },
    page && {
      name: "Status",
      selector: (row) => row.status,
    },
    page && {
      name: "Date",
      selector: (row) => row.date,
    },
    pending && {
      name: "Actions",
      button: true,
      cell: (row, column) => (
        <div className="flex space-x-2 items-center">
          <button
            className="bg-green-500 py-1 px-4 w-[80px] rounded-md"
            onClick={() => approveDeposit(row.depositId)}
          >
            Approve
          </button>
          <button
            className="bg-red-600 py-1 px-4 w-[80px] rounded-md"
            onClick={() => {
              declineDeposit(row.depositId);
            }}
          >
            Decline
          </button>
        </div>
      ),
    },
  ];

  let data;

  if (pending) {
    data = pendingDeposits.map((deposit, index) => {
      return {
        id: index,
        depositId: deposit._id,
        asset: deposit.asset.toUpperCase(),
        network: deposit.network,
        amount: deposit.amount,
        txnId: deposit.txnId,
        status: deposit.status,
        date: deposit.createdAt.slice(0, 10),
        user: deposit.user?.name,
      };
    });
  } else {
    data = deposits.map((deposit, index) => {
      return {
        id: index,
        depositId: deposit._id,
        asset: deposit.asset.toUpperCase(),
        network: deposit.network,
        amount: deposit.amount,
        txnId: deposit.txnId,
        status: deposit.status,
        date: deposit.createdAt.slice(0, 10),
        user: deposit.user?.name,
      };
    });
  }
  return (
    <div>
      <DataTable pagination theme="default" columns={columns} data={data} />
    </div>
  );
};

export default Deposits;
