import React, { useContext } from "react";
import SidebarComponent from "../../components/dashboard/Sidebar";
import { AuthContext } from "../../context/authContext";
import Loader from "../../components/Loader";
import KycList from "../../components/dashboard/kyc";

const PendingKyc = () => {
  const { admin } = useContext(AuthContext);
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={admin} />
      </div>
      <div className="p-4 sm:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          <div>
            <KycList pending={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingKyc;
