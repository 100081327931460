const formatMessage = (str) => {
  if (!str) return "";

  // Make first letter capital
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default formatMessage;

export function AddComma(MyString) {
  const str = MyString.split(".");
  if (str[0].length >= 3) {
    str[0] = str[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (str[1] && str[1].length >= 3) {
    str[1] = str[1].replace(/(\d{3})/g, "$1, ");
  }
  MyString = str.join(".");
  return MyString;
}
